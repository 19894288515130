import React, { FC, ReactNode } from 'react';

import {
  SoloApplicationProfileBannerButtons,
  SoloApplicationProfileMotionBannerButtons,
  SoloApplicationProfileTopContent,
} from './solo-application-fragments';
import { Children } from './types';

// If you add a fragment, update the `FragmentDemo` story of the solo-application-view.stories.tsx file
// So we can easily see where the children are injected
export function useGetFragment(children: Children) {
  const profileBannerFragments = useGetFragmentOfType(
    children,
    SoloApplicationProfileBannerButtons
  );
  const profileTopFragments = useGetFragmentOfType(
    children,
    SoloApplicationProfileTopContent
  );
  const profileMotionBannerButtonsFragments = useGetFragmentOfType(
    children,
    SoloApplicationProfileMotionBannerButtons
  );

  if (!children) {
    return {};
  }

  return {
    profileBannerFragments,
    profileTopFragments,
    profileMotionBannerButtonsFragments,
  };
}

const useGetFragmentOfType = (
  children: Children,
  nodeType: FC<{ children: ReactNode }>
) => {
  const fragments = React.Children.toArray(children).filter((element) =>
    isNodeType(element, nodeType)
  );

  return fragments.length ? fragments : null;
};

function isNodeType(element: ReactNode, nodeType: FC<{ children: ReactNode }>) {
  return (
    React.isValidElement(element) &&
    (element.type === nodeType ||
      (typeof element.type !== 'string' &&
        element.type.name === nodeType.displayName))
  );
}
