import { ProfileMatchCategory } from '@collective/data-type';
import {
  Box,
  Collapse,
  Divider,
  Flex,
  IconCheck,
  IconCheck2,
  IconChevronDown,
  IconChevronUp,
  IconError,
  IconExclamationCircle,
  IconFourPointedStar,
  IconX,
  List,
  ListIcon,
  ListItem,
  Tag,
  Text,
  useBoolean,
} from '@collective/ui';
import { useTranslation } from 'react-i18next';

import { OpportunityEvaluation } from './types';

type SoloApplicationEvaluationProps = {
  evaluation: OpportunityEvaluation;
};

export const SoloApplicationEvaluation = ({
  evaluation,
}: SoloApplicationEvaluationProps) => {
  const { t } = useTranslation();

  const categoryLabel = {
    [ProfileMatchCategory.StrongMatch]: t('profileMatchCategory.strongMatch'),
    [ProfileMatchCategory.PotentialMatch]: t(
      'profileMatchCategory.potentialMatch'
    ),
    [ProfileMatchCategory.NotAGoodFit]: t('profileMatchCategory.notAGoodFit'),
  };

  const colors = {
    [ProfileMatchCategory.StrongMatch]: 'success.700',
    [ProfileMatchCategory.PotentialMatch]: 'decorative.sky.500',
    [ProfileMatchCategory.NotAGoodFit]: 'critical.500',
  };

  const bg = {
    [ProfileMatchCategory.StrongMatch]: 'success.200',
    [ProfileMatchCategory.PotentialMatch]: 'decorative.sky.100',
    [ProfileMatchCategory.NotAGoodFit]: 'critical.100',
  };

  const [isCollapsed, setCollapsed] = useBoolean(false);

  return (
    <Box
      p="20px"
      background="primary.1"
      border="1px solid var(--chakra-colors-primary-500)"
      borderRadius="12px"
    >
      <Flex align="center">
        <Flex align="center" gap="8px">
          <IconFourPointedStar color="primary.600" size="xs" />
          <Text variant="desktop-m-medium" color="primary.800">
            {t('profile.application.evaluation.title')}
          </Text>
          <Tag
            size="md"
            color={colors[evaluation.category]}
            bg={bg[evaluation.category]}
            label={categoryLabel[evaluation.category]}
            leftIcon={
              evaluation.category === ProfileMatchCategory.NotAGoodFit
                ? IconX
                : evaluation.category === ProfileMatchCategory.PotentialMatch
                ? IconExclamationCircle
                : IconCheck2
            }
          />
        </Flex>
        <Box
          onClick={setCollapsed.toggle}
          ml="auto"
          mr={0}
          cursor="pointer"
          color="rythm.600"
          sx={{ 'svg path': { strokeWidth: '1px' } }}
        >
          {isCollapsed ? <IconChevronDown /> : <IconChevronUp />}
        </Box>
      </Flex>
      <Collapse in={!isCollapsed} speed="fast">
        <Divider my="20px" />
        <Text variant="desktop-m-medium" pb="8px">
          {t('profile.application.evaluation.summary')}
        </Text>
        <Text variant="desktop-m-regular" pb="20px">
          {evaluation.summary}
        </Text>
        <Text variant="desktop-m-medium" pb="8px">
          {t('profile.application.evaluation.aiMatchScore', {
            score: evaluation.overallScore,
          })}
        </Text>
        <List color="rythm.900" spacing="8px">
          {evaluation.reasons.map((reason) => (
            <ListItem key={reason}>
              <Text variant="desktop-m-regular">
                <ListIcon
                  as={
                    evaluation.category === ProfileMatchCategory.NotAGoodFit
                      ? IconError
                      : IconCheck
                  }
                  w={4}
                  h={4}
                  color={colors[evaluation.category]}
                />
                {reason}
              </Text>
            </ListItem>
          ))}
        </List>
      </Collapse>
    </Box>
  );
};
