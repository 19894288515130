import { AspectRatio, Avatar, Box, Flex, Image } from '@collective/ui';

import { SoloApplicationStatusBadge } from './solo-application-status-badge';
import { OpportunityMember, Status } from './types';

type SoloApplicationCoverProps = {
  member?: OpportunityMember;
  isFavorite?: boolean;
  status?: Status;
  statusBy?: string;
};

export const SoloApplicationCover = ({
  member,
  isFavorite,
  status,
  statusBy,
}: SoloApplicationCoverProps) => {
  return (
    <>
      <AspectRatio ratio={1584 / 396}>
        <Image
          src="https://cdn.collective.work/clw7rmyx6000008ieb4in635j.jpg"
          objectFit="cover"
          pointerEvents="none"
        />
      </AspectRatio>
      <Box position="relative" maxW="826px" w="100%" mx="auto" h="0px">
        <Flex
          top={{ base: '-52px', md: '-100px' }}
          left="0px"
          position="absolute"
          zIndex={1}
          align="center"
          gap={{ base: 2, md: 6 }}
          px="20px"
        >
          <Flex
            w={{ base: '66px', md: '136px' }}
            h={{ base: '66px', md: '136px' }}
            bg="white"
            borderRadius={80}
            justifyContent="center"
            alignItems="center"
          >
            <Avatar
              name={member?.fullname}
              src={member?.pictureUrl}
              size={{ base: 'xxlRound', md: 'xxxxlRound' }}
              withSkeleton
              isLoading={!member}
            />
          </Flex>
          <SoloApplicationStatusBadge
            isFavorite={isFavorite}
            status={status}
            statusBy={statusBy}
          />
        </Flex>
      </Box>
    </>
  );
};
