import { environment as base } from './environment';

export const environment: typeof base = {
  production: true,
  productAnalytics: {
    enabled: true, // set this to true locally to test june
    juneKey: 'dfHlCvQ51dJ4DTwi', // june API key
    juneClientKey: '9QziLBrgPwkXro64', // june client API key
    posthogKey: 'phc_PIlfBCY1wY5DrsB1T7S5KNXzcR9kbFBqO1tQADwOAJG',
  },
};
