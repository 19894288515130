// In a different file for Next

export type SupportedLanguage = keyof typeof supportedLanguages;

export const supportedLanguages = {
  fr: 'fr',
  en: 'en',
} as const;
export const SUPPORTED_LANGUAGES = Object.values(supportedLanguages);

export const getLanguage = (navigatorLanguages?: readonly string[]): string => {
  if (!navigatorLanguages) {
    return supportedLanguages.en;
  }

  return navigatorLanguages.includes('fr') ||
    navigatorLanguages.includes('fr-FR')
    ? supportedLanguages.fr
    : supportedLanguages.en;
};
