import { ShortlistPricing } from '@collective/data-type';
import { Box, Flex, Heading, Skeleton, Text } from '@collective/ui';
import { ALL_IDENTIFIER, TITLE_IDENTIFIER } from '@collective/utils/frontend';
import { times } from 'lodash';
import { ReactNode } from 'react';

import { HighlightText } from '../../highlighting/highlight-text';
import { ApprovalType } from '../sections/trusted-by-section/types';
import { SoloApplicationApprovalStats } from './solo-application-approval-stats';
import { SoloApplicationDailyRateBadge } from './solo-application-daily-rate-badge';
import { OpportunityMember } from './types';

type SoloApplicationProfileBannerProps = {
  member: OpportunityMember;
  shortlistPricing: ShortlistPricing;
  additionalTags?: ReactNode[] | null;
  approvals: ApprovalType[] | null;
  calendarUrl?: string | null;
  profileBannerFragments?: ReactNode;
};

export const SoloApplicationProfileBanner = ({
  member: { fullname, position, dailyRate },
  shortlistPricing,
  additionalTags,
  approvals,
  profileBannerFragments,
}: SoloApplicationProfileBannerProps) => {
  return (
    <SoloApplicationProfileBannerBase
      fullName={
        <Heading as="h1" mb={1} variant="desktop-xl-bold">
          {fullname}
        </Heading>
      }
      position={
        !!position && (
          <Text as="h2" mb={2} variant="desktop-m-medium">
            {position}
          </Text>
        )
      }
      connections={<SoloApplicationApprovalStats approvals={approvals} />}
      dailyRateBadge={
        !additionalTags &&
        (shortlistPricing === ShortlistPricing.DailyRate ||
          shortlistPricing === ShortlistPricing.BudgetRange) && (
          <SoloApplicationDailyRateBadge dailyRate={dailyRate} />
        )
      }
      rightButtons={profileBannerFragments}
      additionalTags={!!additionalTags && additionalTags}
    />
  );
};

export const SoloApplicationProfileBannerLoader = () => {
  return (
    <SoloApplicationProfileBannerBase
      fullName={<Skeleton height="30px" w="250px" mb={1} />}
      position={<Skeleton height="18px" w="320px" mb={1} />}
      connections={
        <Flex align="center" gap="8px">
          <Skeleton height="26px" w="75px" mb={1} />
          <Skeleton height="18px" w="100px" />
        </Flex>
      }
      dailyRateBadge={undefined}
      additionalTags={
        <>
          <Flex gap={2} mb={1}>
            {times(5).map((value) => (
              <Skeleton key={value} height="28px" w="250px" />
            ))}
          </Flex>
          <Flex gap={1} pt="20px">
            {times(5).map((value) => (
              <Skeleton key={value} height="28px" w="90px" />
            ))}
          </Flex>
        </>
      }
    />
  );
};

type SoloApplicationProfileBannerBaseProps = {
  fullName: ReactNode;
  position: ReactNode;
  connections: ReactNode;
  dailyRateBadge: ReactNode;
  rightButtons?: ReactNode;
  additionalTags?: ReactNode;
};

const SoloApplicationProfileBannerBase = ({
  fullName,
  position,
  connections,
  dailyRateBadge,
  rightButtons,
  additionalTags,
}: SoloApplicationProfileBannerBaseProps) => {
  return (
    <>
      <Flex
        gap="8px"
        justify="space-between"
        direction={{ base: 'column', md: 'row' }}
        flexWrap="wrap"
      >
        <HighlightText identifiers={[TITLE_IDENTIFIER, ALL_IDENTIFIER]}>
          <Box flex={1} minW="300px">
            {fullName}
            {position}
            {connections}
            {dailyRateBadge}
          </Box>
        </HighlightText>

        <Flex
          gap="8px"
          direction={{ base: 'column', md: 'row' }}
          flexWrap="wrap"
        >
          {rightButtons}
        </Flex>
      </Flex>
      {additionalTags}
    </>
  );
};
