import { FocusEvent } from 'react';
import {
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

import {
  DatePicker,
  DatePickerProps,
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '../../..';

type FieldDatePickerProps = DatePickerProps & {
  label?: string;
};

export const FieldDatePicker = <
  FormFieldValues extends FieldValues = FieldValues,
  FieldName extends FieldPath<FormFieldValues> = FieldPath<FormFieldValues>
>({
  name,
  control,
  rules,
  shouldUnregister,
  defaultValue,
  label,
  ...rest
}: UseControllerProps<FormFieldValues, FieldName> & FieldDatePickerProps) => {
  const {
    field: { ref, ...field },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
    shouldUnregister,
    defaultValue,
  });

  const isInvalid = !!error;

  const onInputBlur = (e: FocusEvent<HTMLDivElement, Element>) => {
    field.onBlur();
    rest?.onBlur?.(e);
  };
  const onInputChange = (value: string | null) => {
    field.onChange(value);
    rest?.onChange?.(value);
  };

  return (
    <FormControl isInvalid={isInvalid}>
      {label && <FormLabel variant="simple">{label}</FormLabel>}
      <DatePicker
        {...field}
        {...rest}
        name={name}
        onChange={onInputChange}
        onBlur={onInputBlur}
        isInvalid={isInvalid}
      />
      {error?.message && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  );
};
