import { Avatar, AvatarProps, Flex, IconBuildingOffice2 } from '@collective/ui';
import { avatarWithBorderProperties } from '@collective/ui/themes/components/avatar';

type CompanyLogoProps = {
  logoUrl?: string | null;
  name?: string | null;
  avatarProps?: AvatarProps;
};

export const CompanyLogo = ({
  logoUrl,
  name,
  avatarProps,
}: CompanyLogoProps) => {
  const size = typeof avatarProps?.size === 'string' ? avatarProps.size : 'xl';

  const dimension = avatarWithBorderProperties[size].firstBoxStyle;

  return logoUrl ? (
    <Avatar
      src={logoUrl}
      name={name}
      size="lg"
      showBorder
      borderColor="black"
      position="relative"
      {...avatarProps}
    >
      {(hasLoaded) =>
        hasLoaded ? null : (
          <Flex
            w="100%"
            h="100%"
            background="white"
            alignItems="center"
            justifyContent="center"
            position="absolute"
            inset={0}
            zIndex={1}
          >
            <IconBuildingOffice2 color="primary.900" />
          </Flex>
        )
      }
    </Avatar>
  ) : (
    <Flex
      w={dimension.width}
      h={dimension.height}
      borderRadius={dimension.borderRadius}
      border="2px solid"
      borderColor="black"
      background="white"
      alignItems="center"
      justifyContent="center"
      flexShrink={0}
    >
      <IconBuildingOffice2 />
    </Flex>
  );
};
