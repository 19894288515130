export const LINKEDIN_BASE_URL = 'https://www.linkedin.com/in';
export const LINKEDIN_COMPANY_BASE_URL = 'https://www.linkedin.com/company';

export const LINKEDIN_URL_REGEX = /linkedin\.[\w\d.]+\/in\/([^?/]+)/;
export const LINKEDIN_SEARCH_USER_URL_REGEX =
  /linkedin\.[\w\d.]+\/search\/results\/people.*/;

export const LINKEDIN_TEST_PREFIX = 'datadog-e2e-test';

export const getLinkedInHandle = (
  url: string | null | undefined
): string | null => {
  const match = url?.match(LINKEDIN_URL_REGEX);

  return match ? match[1] : null;
};

export const normalizeLinkedInUrl = (
  url: string | null | undefined
): string | null => {
  const handle = getLinkedInHandle(url);

  return handle ? `${LINKEDIN_BASE_URL}/${handle}` : null;
};

export const getLinkedInUrlFromHandle = (handle: string) => {
  return `${LINKEDIN_BASE_URL}/${handle}`;
};

export const getLinkedInProfileUrlFromUrn = (code: string) => {
  const profileId = getLinkedInProfileIdFromUrn(code);
  return `${LINKEDIN_BASE_URL}/${profileId}`;
};

// urn looks like this "urn:li:fsd_profile:123456"
export const getLinkedInProfileIdFromUrn = (code: string) => {
  return code.replace('urn:li:fsd_profile:', '');
};

// urn looks like this "urn:li:company:123456"
export const getLinkedInCompanyUrlFromUrn = (code: string) => {
  const companyId = getLinkedInCompanyIdFromUrn(code);
  return `${LINKEDIN_COMPANY_BASE_URL}/${companyId}`;
};

export const getLinkedInCompanyIdFromUrn = (code: string) => {
  return code.replace('urn:li:company:', '');
};

export const isLinkedInTestUser = (handle: string) => {
  return handle.startsWith(LINKEDIN_TEST_PREFIX);
};

// extract if from url such as https://www.linkedin.com/company/2197876/
// all the extract code is to be robust to special cases
// such as
// - https://www.linkedin.com/search/results/all/?keywords=Solidsix -> null
// - https://www.linkedin.com/company/2197876/admin/ -> 2197876
// - https://www.linkedin.com/company/123?test=3&g-5 -> 123
// - https://www.linkedin.com/in/paulpryce -> null
export const extractLinkedInCompanyId = (url: string | null): string | null => {
  if (!url) {
    return null;
  }

  try {
    // Create a URL object to parse the input
    const parsedUrl = new URL(url);

    // Check if it's a LinkedIn URL
    if (!parsedUrl.hostname.includes('linkedin.com')) {
      return null;
    }

    // Split the pathname into segments
    const segments = parsedUrl.pathname.split('/').filter(Boolean);

    // Check if it's a company URL
    if (segments[0] !== 'company') {
      return null;
    }

    // Get the company ID (second segment)
    const companyId = segments[1];

    // Ensure company ID consists only of numbers
    if (!companyId || !/^\d+$/.test(companyId)) {
      return null;
    }

    return companyId;
  } catch (error) {
    return null;
  }
};
