import posthog, { PostHog } from 'posthog-js';
import { useEffect, useState } from 'react';

const POSTHOG_NAME = 'app';

export let POSTHOG_APP: PostHog | null = null;

export function usePosthog(
  enabled: boolean,
  writeKey: string,
  options?: {
    autocapture?: boolean;
    trackPageView?: boolean;
    trackPageLeave?: boolean;
    disableSessionRecording?: boolean;
  }
) {
  const [analytics, setAnalytics] = useState<PostHog | void>();

  useEffect(() => {
    if (!enabled) {
      return;
    }

    const loadAnalytics = () => {
      POSTHOG_APP = posthog.init(
        writeKey,
        {
          // this domain is a cloudfront distribution, created to proxy events without them
          // being discarded by ad blockers - see https://posthog.com/docs/integrate/proxy
          api_host: 'https://e.collective.work',
          // https://posthog.com/docs/integrate/client/js#autocapture
          // turning off autocapture as it generated way too many events and would make us reach our quotas
          autocapture: options?.autocapture || false,
          //
          // https://posthog.com/docs/integrate/client/js#config
          // this turns of automatically capture - but we still do it for pageviews
          capture_pageview: options?.trackPageView || false,
          capture_pageleave: options?.trackPageLeave || false,
          // https://posthog.com/manual/recordings#recording-configurations
          // show all inputs in replay except password ones
          session_recording: {
            maskAllInputs: false,
            maskInputOptions: {
              password: true,
            },
          },
          // https://posthog.com/manual/recordings#further-controls
          // we disable session recording by default, to only enable it when a user is logged in
          disable_session_recording: options?.disableSessionRecording || true,
        },
        POSTHOG_NAME
      ) as PostHog;

      setAnalytics(POSTHOG_APP);
    };
    loadAnalytics();
  }, [
    enabled,
    options?.autocapture,
    options?.trackPageView,
    options?.trackPageLeave,
    options?.disableSessionRecording,
    writeKey,
  ]);

  return analytics;
}
