import { Analytics } from '@june-so/analytics-next';
import { createContext, ReactNode } from 'react';

import { useDatadogRum } from './use-datadog-rum';
import { useJune } from './use-june';
import { usePosthog } from './use-posthog';

export const AnalyticsTrackingContext = createContext<
  { analytics?: Analytics; enabled: boolean } | undefined
>(undefined);

type AnalyticsTrackingProviderProps = {
  children: ReactNode;
  enabled: boolean;
  juneKey: string;
  postHogAnalyticsKey: string;
  datadogRumKey: string;
  serviceName?: string;
  options?: {
    autocapture?: boolean;
    trackPageView: boolean;
    trackPageLeave: boolean;
    disableSessionRecording?: boolean;
  };
};

// Following the doc here - https://www.june.so/docs/react
export function AnalyticsTrackingProvider({
  children,
  enabled,
  juneKey,
  postHogAnalyticsKey,
  datadogRumKey,
  serviceName,
  options,
}: AnalyticsTrackingProviderProps) {
  const analytics = useJune(enabled && !!juneKey.length, juneKey);
  usePosthog(
    enabled && !!postHogAnalyticsKey.length,
    postHogAnalyticsKey,
    options
  );
  useDatadogRum(enabled && !!datadogRumKey.length, datadogRumKey, serviceName);

  return (
    <AnalyticsTrackingContext.Provider value={{ analytics, enabled }}>
      {children}
    </AnalyticsTrackingContext.Provider>
  );
}
