/**
 * Sometimes, we can't use react-use/useCookie because of a cache in the lib
 * For example when we get the impersonate cookie after a logout
 * it doesn't refresh the react-use/useCookie memory (the cookie is unset on backend side)
 *
 * https://github.com/streamich/react-use/blob/master/src/useCookie.ts
 * It's using a state and a callback so as long the name is not change, the result will remain the same
 *
 * So the purpose of this lib is to get a way to get cookies using low level code
 * Try to use react-use/useCookie in priority tho'
 */

export function getCookie(name: string): string | null {
  const cookieValue = document.cookie
    .split('; ')
    .filter((cookie) => cookie.startsWith(`${name}=`));

  return cookieValue.length ? cookieValue[0].substring(name.length + 1) : null;
}
