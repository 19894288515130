import { ReactNode } from 'react';

export function SoloApplicationProfileBannerButtons({
  children,
}: {
  children: ReactNode;
}) {
  return children;
}
export function SoloApplicationProfileTopContent({
  children,
}: {
  children: ReactNode;
}) {
  return children;
}
export function SoloApplicationProfileMotionBannerButtons({
  children,
}: {
  children: ReactNode;
}) {
  return children;
}
