import {
  AvailabilityStatus,
  Client_FullMemberFragment,
  Collective_GetNetworkUser,
  PublicPages_GetPublicUser,
  Recruiter_SearchUsers,
  WorkPreference,
} from '@collective/data-type';
import {
  Box,
  Flex,
  IconCheck,
  IconCompany,
  IconMapPin,
  IconMoney,
  IconRocket,
  IconThumbUp,
  Text,
  Tooltip,
} from '@collective/ui';
import {
  ArrayElement,
  displayCurrencyFromNumber,
} from '@collective/utils/helpers';
import { TFunction, Trans, useTranslation } from 'react-i18next';

import { UserPill } from './user-pill';

type RecruiterUser = Omit<
  ArrayElement<Recruiter_SearchUsers['results']['users']>,
  'isImported' | 'crmCounter'
>;
type NetworkUser = Collective_GetNetworkUser['user'];
type PublicProfileUser = PublicPages_GetPublicUser['user'];
type ClientUser = Client_FullMemberFragment['user'] & {
  availabilityValidUntil?: never;
  availabilityStatus?: never;
};

type UserType = Pick<
  NonNullable<ClientUser | RecruiterUser | NetworkUser | PublicProfileUser>,
  | 'dailyRate'
  | 'approvalCount'
  | 'workPreference'
  | 'missionCount'
  | 'workLocation'
>;

type UserPillsProps = {
  user: UserType;
};

export const UserPills = ({ user }: UserPillsProps) => {
  return (
    <Flex mt={5} gap={2} flexWrap="wrap" _empty={{ mt: 0 }}>
      <DailyRatePill user={user} />
      <ApprovalCountPill user={user} />
      <MissionCountPill user={user} />
      <WorkLocationPill user={user} />
      <WorkPreferencePill user={user} />
    </Flex>
  );
};

export const DailyRatePill = ({ user }: UserPillsProps) => {
  if (!user.dailyRate) {
    return null;
  }

  return (
    <UserPill
      icon={<IconMoney color="primary.500" size="sm" />}
      content={
        <Trans
          ns="common"
          i18nKey="profile.tags.dailyRate"
          components={{
            dark: <Text variant="desktop-m-medium" as="span" />,
          }}
          values={{
            rate: displayCurrencyFromNumber(user.dailyRate),
          }}
        />
      }
      colorScheme="blue"
    />
  );
};

export const AvailabilityPill = ({ user }: { user: RecruiterUser }) => {
  const { t } = useTranslation('common');

  const { availabilityStatus } = user;

  if (!availabilityStatus) {
    return null;
  }

  const availableStatuses = [
    AvailabilityStatus.ReadyForInterview,
    AvailabilityStatus.OpenToOpportunities,
  ];

  if (!availableStatuses.includes(availabilityStatus)) {
    return null;
  }

  return (
    <Tooltip label={t('profile.tags.readyToInterviewTooltip')}>
      <Box>
        <UserPill
          icon={<IconCheck size="xs" />}
          content={t('profile.tags.readyToInterview')}
          colorScheme="green"
        />
      </Box>
    </Tooltip>
  );
};

export const ApprovalCountPill = ({ user }: UserPillsProps) => {
  if (!user.approvalCount) {
    return null;
  }

  return (
    <UserPill
      icon={<IconThumbUp color="decorative.sky.500" size="xs" />}
      content={
        <Trans
          ns="common"
          i18nKey="profile.tags.approvedBy"
          components={{
            skyBlue: (
              <Text
                color="decorative.sky.500"
                variant="desktop-m-medium"
                as="span"
              />
            ),
          }}
          values={{
            count: user.approvalCount,
            total: user.approvalCount > 20 ? '20+' : user.approvalCount,
          }}
        />
      }
      colorScheme="sky"
    />
  );
};

export const MissionCountPill = ({ user }: UserPillsProps) => {
  const { t } = useTranslation('common');

  if (!user.missionCount) {
    return null;
  }

  return (
    <UserPill
      icon={<IconRocket color="rythm.600" size="xs" />}
      content={t('profile.tags.missionAchieved', {
        count: user.missionCount,
      })}
      colorScheme="basic"
    />
  );
};

export const WorkLocationPill = ({ user }: UserPillsProps) => {
  if (!user.workLocation) {
    return null;
  }

  return (
    <UserPill
      icon={<IconMapPin color="rythm.600" size="xs" />}
      content={user.workLocation}
      colorScheme="basic"
    />
  );
};

export const WorkPreferencePill = ({ user }: UserPillsProps) => {
  const { t } = useTranslation('common');

  if (!user.workPreference) {
    return null;
  }

  return (
    <UserPill
      icon={<IconCompany color="rythm.600" size="sm" />}
      content={getWorkPreferenceLabel(t, user.workPreference)}
      colorScheme="basic"
    />
  );
};

const getWorkPreferenceLabel = (
  t: TFunction,
  workPreference: WorkPreference | null | undefined
) => {
  switch (workPreference) {
    case WorkPreference.Flexible:
      return t('profile.tags.hybrid');
    case WorkPreference.Remote:
      return t('profile.tags.remoteOnly');
    default:
      return null;
  }
};
