import { FALLBACK_LANGUAGE } from '@collective/utils/helpers';
import cuid from 'cuid';

import { Collective_ClientType } from '../../generated';
import { generateCompany } from './company';

export const generateClient = (
  props?: Partial<Collective_ClientType>,
  n = 0
): Collective_ClientType => ({
  id: cuid(),
  companyId: cuid(),
  userId: cuid(),
  company: generateCompany(undefined, n),
  language: FALLBACK_LANGUAGE,
  ...props,
});
