import {
  AvatarGroup,
  Box,
  Flex,
  Heading,
  IconButton,
  IconLinkedIn,
  Link,
  Modal,
  Text,
  useBoolean,
} from '@collective/ui';
import { isPresent } from '@collective/utils/helpers';
import { displayName } from '@collective/utils/shared';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { TrustedByRow } from '../sections/trusted-by-section/trusted-by-row';
import { ApprovalType } from '../sections/trusted-by-section/types';
import { OPEN_APPROVALS_EVENT } from '../sections/trusted-by-section/utils';
import { ProfileBox } from './profile-box';
import { DISPLAYED_TOP_APPROVALS_COUNT } from './utils';

type SoloApplicationApprovalsSectionProps = {
  approvals: ApprovalType[] | null;
};

export const SoloApplicationApprovalsSection = ({
  approvals,
}: SoloApplicationApprovalsSectionProps) => {
  const { t } = useTranslation('common');
  const [isModalOpen, setIsModalOpen] = useBoolean(false);

  useEffect(() => {
    document.addEventListener(OPEN_APPROVALS_EVENT, setIsModalOpen.on);

    return () => {
      document.removeEventListener(OPEN_APPROVALS_EVENT, setIsModalOpen.on);
    };
  }, [setIsModalOpen]);

  if (!approvals?.length) {
    return null;
  }

  const [topApprovals, otherApprovals] = approvals.reduce(
    (acc, value, index) => {
      if (index < DISPLAYED_TOP_APPROVALS_COUNT) {
        return [[...acc[0], value], acc[1]];
      } else {
        return [acc[0], [...acc[1], value]];
      }
    },
    [[], []] as [ApprovalType[], ApprovalType[]]
  );
  const otherApprovalAvatars = otherApprovals
    .map((approval, index) =>
      approval.pictureUrl
        ? {
            id: index.toString(),
            name: displayName({
              firstname: approval.firstname,
              lastname: approval.lastname,
            }),
            src: approval.pictureUrl,
          }
        : null
    )
    .filter(isPresent);

  return (
    <ProfileBox>
      <Box>
        <Heading as="h3" variant="desktop-m-bold" mb={5}>
          {t('profile.application.trustedBy.header')}
        </Heading>
        <Flex flexDir="column" gap={6}>
          {topApprovals.map((topApproval, index) => (
            <TrustedByRow
              key={index}
              approval={topApproval}
              withSeparator={index < topApprovals.length - 1}
              actions={
                <Link href={topApproval.linkedInUrl} target="_blank">
                  <IconButton
                    variant="secondary"
                    icon={<IconLinkedIn />}
                    aria-label={t(
                      'profile.application.trustedBy.seeLinkedInButton'
                    )}
                  />
                </Link>
              }
            />
          ))}
        </Flex>
        {!!otherApprovals.length && (
          <Flex
            mt={6}
            justifyContent="flex-end"
            alignItems="center"
            gap={2}
            w="fit-content"
            ml="auto"
            mr={0}
            cursor="pointer"
            onClick={setIsModalOpen.on}
            _hover={{ textDecoration: 'underline' }}
          >
            <Text color="rythm.700">
              {t('profile.application.trustedBy.otherApprovals', {
                count: otherApprovals.length,
              })}
            </Text>
            <AvatarGroup
              avatars={otherApprovalAvatars}
              borderColor="rythm.900"
            />
          </Flex>
        )}
        <ModalList
          approvals={approvals}
          isOpen={isModalOpen}
          onClose={setIsModalOpen.off}
        />
      </Box>
    </ProfileBox>
  );
};

type ModalListProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  approvals: ApprovalType[];
};

const ModalList = ({ isOpen, onClose, approvals }: ModalListProps) => {
  const { t } = useTranslation('common');

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t('profile.application.trustedBy.header')}
      showHeader
    >
      <Flex p="20px 16px 42px 16px" flexDir="column" gap={6}>
        {approvals.map((approval, index) => (
          <TrustedByRow
            key={index}
            approval={approval}
            withSeparator={index !== approvals.length - 1}
            actions={
              <Box>
                <Link href={approval.linkedInUrl} target="_blank">
                  <IconButton
                    variant="secondary"
                    icon={<IconLinkedIn />}
                    aria-label={t(
                      'profile.application.trustedBy.seeLinkedInButton'
                    )}
                  />
                </Link>
              </Box>
            }
          />
        ))}
      </Flex>
    </Modal>
  );
};
