import { VirtualItem } from '@tanstack/react-virtual';
import { last } from 'lodash';
import { useEffect } from 'react';

type Data = {
  virtualizedItems: VirtualItem[];
  page: number;
  hasMore: boolean;
  loadedItemsCount: number;
  setPage: (newPage: number) => void;
  isLoading: boolean;
};

export const useVirtualizedNextPage = ({
  virtualizedItems,
  page,
  hasMore,
  loadedItemsCount,
  setPage,
  isLoading,
}: Data) => {
  const lastIndex = last(virtualizedItems)?.index;

  useEffect(() => {
    if (!lastIndex) {
      return;
    }

    if (lastIndex >= loadedItemsCount - 1 && hasMore && !isLoading) {
      setPage(page + 1);
    }
  }, [page, hasMore, setPage, loadedItemsCount, isLoading, lastIndex]);
};
