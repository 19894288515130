import { datadogRum } from '@datadog/browser-rum';
import { useCallback, useContext } from 'react';

import { AnalyticsTrackingContext } from './analytics-tracking-context';
import { POSTHOG_APP } from './use-posthog';

// This hook is used to reset all the tracking tools after logout.
// https://posthog.com/docs/libraries/js#reset-after-logout
export const useAnalyticsTrackingReset = () => {
  const analyticsTrackingContext = useContext(AnalyticsTrackingContext);

  return useCallback(() => {
    // reset all the tracking tools if they were enabled
    analyticsTrackingContext?.analytics?.reset();
    POSTHOG_APP?.reset(); // posthog analytics backup
    datadogRum.clearUser();

    // we stop the recording as no more user, so not interesting to track it
    // anymore
    POSTHOG_APP?.stopSessionRecording();
    datadogRum.stopSessionReplayRecording();
  }, [analyticsTrackingContext]);
};
