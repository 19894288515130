import { stringify } from 'query-string';

type GetCollectivePublicPageUrlQuery = {
  collectiveSelection?: string;
  source?: string;
  t?: string;
};

const PUBLIC_BASE_URL =
  process.env.NX_PUBLIC_PAGE_URL || 'http://public.localhost:4201';

export const getCollectivePublicPageUrl = (
  slug: string,
  query: GetCollectivePublicPageUrlQuery = {},
  path?: string,
  isPersonal: boolean = false
) => {
  const basePath = isPersonal ? 'profile' : 'collective';
  const url = `${PUBLIC_BASE_URL}/${basePath}/${slug}`;
  const fullUrl = path ? `${url}/${path}` : url;
  return Object.keys(query).length ? `${fullUrl}?${stringify(query)}` : fullUrl;
};

export const getPublicProfileLink = (slug: string) => {
  return `${PUBLIC_BASE_URL}/profile/${slug}`;
};

export const getPublicJobLink = (slug: string) => {
  return `${PUBLIC_BASE_URL}/job/${slug}`;
};

export const getPublicJobBoardLink = () => {
  return `${PUBLIC_BASE_URL}/job`;
};

export const getLandingPageLink = (slug: string) => {
  return `${PUBLIC_BASE_URL}/freelance/${slug}`;
};

export const getOpenSearchPageLink = (language: string, listId?: string) => {
  return listId
    ? `${PUBLIC_BASE_URL}/search/${language}/${listId}`
    : `${PUBLIC_BASE_URL}/search/${language}`;
};

export const getCaseStudyPublicPageUrl = (
  collectiveSlug: string,
  caseStudySlug: string
) => {
  const collectivePageUrl = getCollectivePublicPageUrl(collectiveSlug);
  return `${collectivePageUrl}/case-study/${caseStudySlug}`;
};
