export const PROFILE_TITLE_MAX_LENGTH = 50;
export const PROFILE_INTRO_MAX_LENGTH = 500;

export const RECRUITER_PROFILE_BIO_MAX_LENGTH = 500;

// forced to get lodash this way otherwise the public page build fails
// https://github.com/lodash/lodash/issues/5525
// Using the unstable_allowDynamic didn't work
export function orderWorkExperiences<
  T extends { ranking?: number | null | undefined }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
>(lodash: any, currentXP: T[], pastXP: T[]) {
  return [
    ...lodash
      .sortBy(currentXP, 'startDate')
      .reverse()
      .sort((a: T, b: T) => {
        if (lodash.isNil(a.ranking) && !lodash.isNil(b.ranking)) {
          return 1;
        } else if (!lodash.isNil(a.ranking) && lodash.isNil(b.ranking)) {
          return -1;
        } else {
          return (a.ranking || 0) - (b.ranking || 0);
        }
      }),
    ...lodash.chain(pastXP).sortBy(['endDate', 'startDate']).reverse().value(),
  ];
}
