export const AUTH_COOKIE_NAME = 'auth';
export const CLIENT_AUTH_COOKIE_NAME = 'client-auth';
export const ADMIN_AUTH_COOKIE_NAME = 'admin-auth';
export const AUTH_REFRESH_COOKIE_NAME = 'auth-refresh';

// goal of the from-collective cookie is to detect users from collective that use the admin
// to prevent tracking us and our sessions (in recordings, product analytics events, etc...)
// as it just adds noise and generates more data we have to pay for
export const FROM_COLLECTIVE_COOKIE_NAME = 'from-collective';

// used to store info of the user we impersonate to display some banners to the admin
export const IMPERSONATE_INFO_COOKIE_NAME = 'impersonate-info';

// used to store info of a client we impersonate to display some banners to an admin
export const IMPERSONATE_CLIENT_COOKIE_NAME = 'impersonate-client';

// used to store info about accepted mobile placeholder
export const MOBILE_PLACEHOLDER_COOKIE_NAME = 'mobile-placeholder';

// used to store client email in price simulator flow
export const PRICE_SIMULATOR_EMAIL_COOKIE_NAME = 'price-simulator-email';

// used to enable proposal editor for proposal with status CONTRACT_SUBMITTED
export const ADMIN_PROPOSAL_EDIT = 'admin-proposal-edit';

export interface ImpersonatingInfo {
  admin: string;
  userImpersonatedEmail: string;
}

export type ClientImpersonateInfo = {
  admin: string;
  clientName: string;
};
