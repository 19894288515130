import {
  TEST_EMAIL_DATADOG_DOMAIN,
  TEST_EMAIL_PREFIX,
  TEST_EMAIL_SYNTHETICS_DOMAIN,
} from './constants';
import { FREE_EMAILS, TEMPORARY_EMAILS } from './email.constants';

export const cleanEmail = (email: string): string => {
  return email.toLowerCase().trim();
};

export const getEmailDomain = (email: string): string => {
  return email.split('@')[1];
};

export const isTestUser = (email: string): boolean => {
  return (
    email.includes(TEST_EMAIL_DATADOG_DOMAIN) ||
    email.includes(TEST_EMAIL_SYNTHETICS_DOMAIN) ||
    email.includes(TEST_EMAIL_PREFIX)
  );
};

export const isFreeEmail = (email: string): boolean => {
  return FREE_EMAILS.some((e) => email.endsWith(e));
};

export const isTemporaryEmail = (email: string): boolean => {
  return TEMPORARY_EMAILS.some((e) => email.endsWith(e));
};
