import { Button, ButtonProps } from '@collective/ui';
import { forwardRef, MouseEventHandler } from 'react';
import { Link, LinkProps } from 'react-router-dom';

export type ButtonLinkProps = { buttonProps?: ButtonProps } & (
  | LinkProps
  | React.AnchorHTMLAttributes<HTMLAnchorElement>
);

export const ButtonLink = forwardRef<HTMLButtonElement, ButtonLinkProps>(
  function ButtonLink(props, ref) {
    const { children, buttonProps, ...rest } = props;

    if ('to' in rest && rest.to) {
      return (
        <Link {...rest}>
          <Button ref={ref} {...buttonProps}>
            {children}
          </Button>
        </Link>
      );
    }

    if ('href' in rest && rest.href) {
      return (
        <a {...rest}>
          <Button ref={ref} {...buttonProps}>
            {children}
          </Button>
        </a>
      );
    }

    if ('onClick' in rest && rest.onClick) {
      return (
        <Button
          ref={ref}
          onClick={rest.onClick as MouseEventHandler}
          {...buttonProps}
          data-dd-id={'data-dd-id' in rest ? rest['data-dd-id'] : undefined}
        >
          {children}
        </Button>
      );
    }

    // no 'to' or a falsy href, show a disabled button
    return (
      <Button ref={ref} isDisabled role="link" {...buttonProps}>
        {children}
      </Button>
    );
  }
);
