import { Collective_GetMe } from '@collective/data-type';
import { getCookie, getLogger } from '@collective/utils/frontend';
import { IMPERSONATE_INFO_COOKIE_NAME } from '@collective/utils/helpers';
import { displayName } from '@collective/utils/shared';
import { datadogRum } from '@datadog/browser-rum';
import i18next from 'i18next';
import { useContext, useEffect } from 'react';

import { AnalyticsTrackingContext } from './analytics-tracking-context';
import { useAnalyticsTrackingReset } from './use-analytics-tracking-reset';
import { POSTHOG_APP } from './use-posthog';

export const useAnalyticsTrackingIdentify = (
  me: Collective_GetMe['me'] | undefined,
  toIgnorePatterns: string[],
  postIdentifyCallback?: () => void
) => {
  const analyticsTrackingContext = useContext(AnalyticsTrackingContext);
  const resetTracking = useAnalyticsTrackingReset();

  useEffect(() => {
    if (!analyticsTrackingContext) {
      getLogger().error(
        'Missing context AnalyticsTrackingProvider for useAnalyticsTrackingIdentify',
        { me }
      );
      return;
    }

    const { enabled } = analyticsTrackingContext;

    if (!enabled) {
      return;
    }

    // ignore some users for intercom
    const shouldIgnoreUser = toIgnorePatterns.some((pattern) =>
      me?.email.includes(pattern)
    );
    const impersonateCookie = getCookie(IMPERSONATE_INFO_COOKIE_NAME);

    if (shouldIgnoreUser || impersonateCookie) {
      resetTracking();

      if (impersonateCookie) {
        getLogger('analytics').warn(
          'Not recording session as impersonating user'
        );
      }
      return;
    }

    if (!me) {
      return;
    }

    // set the language
    const language = me.language || i18next.resolvedLanguage;

    const traits = {
      name: displayName(me),
      firstname: me.firstname,
      lastname: me.lastname,
      email: me.email,
      avatar: me.pictureUrl,
      birthday: me.birthDate,
      createdAt: me.createdAt,
      phone: me.phoneNumber,
      recruiter: me.isRecruiter,
      // set user as in a collective
      collective: true,
      language,
      // used to debug language detection problems
      i18nextLanguage: i18next.language,
      i18nextResolvedLanguage: i18next.resolvedLanguage,
      browserNavigatorLanguage: navigator.language,
      browserNavigatorLanguages: JSON.stringify(navigator.languages),
    };

    const trackingInits = [
      // identify the user
      analyticsTrackingContext.analytics?.identify(me.id, traits),
      // posthog analytics backup
      POSTHOG_APP?.identify(
        me.id, // distinct_id, required
        traits
      ),
    ];

    Promise.all(trackingInits).then(postIdentifyCallback);

    // only record when logged in
    POSTHOG_APP?.startSessionRecording();

    // datadog analytics
    datadogRum.setUser({
      id: me.id,
      ...traits,
    });
    datadogRum.startSessionReplayRecording();

    // identify all the company and user
    me.members?.forEach((member) => {
      const traits = {
        name: member.collective?.name,
        avatar: member.collective?.logoUrl,
        createdAt: member.collective?.createdAt,
        publicStatus: member.collective?.publicStatus,
        // set company as collective
        collective: true,
      };

      analyticsTrackingContext.analytics?.group(member.collective!.id, traits);

      // posthog analytics backup
      POSTHOG_APP?.group('company', member.collective!.id, traits);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps -- switch from warn to error
  }, [
    me,
    analyticsTrackingContext,
    analyticsTrackingContext?.analytics,
    analyticsTrackingContext?.enabled,
  ]);
};
