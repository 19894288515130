import { Box, IconFile } from '@collective/ui';
import { useTranslation } from 'react-i18next';

import { ButtonLink } from '../../button-link/button-link';

type UserResumeButtonProps = {
  resumeUrl: string | null | undefined;
};

export const UserResumeButton = ({ resumeUrl }: UserResumeButtonProps) => {
  const { t } = useTranslation();

  if (!resumeUrl) {
    return null;
  }

  return (
    <Box>
      <ButtonLink
        href={resumeUrl}
        target="_blank"
        buttonProps={{ variant: 'secondary', leftIcon: <IconFile /> }}
      >
        {t('profile.resume.button')}
      </ButtonLink>
    </Box>
  );
};
