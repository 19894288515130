import { IconHeartFilled, Tag } from '@collective/ui';
import { useTranslation } from 'react-i18next';

import { Status } from './types';

type SoloApplicationStatusBadgeProps = {
  isFavorite?: boolean;
  status?: Status;
  statusBy?: string;
};

export const SoloApplicationStatusBadge = ({
  isFavorite,
  status,
  statusBy,
}: SoloApplicationStatusBadgeProps) => {
  const { t } = useTranslation('common');

  if (!status && !isFavorite) {
    return null;
  }

  switch (status) {
    case 'contacted':
      return (
        <Tag
          size="md"
          bg="success.200"
          color="success.700"
          label={
            statusBy
              ? t('profile.application.badge.contactedBy', { name: statusBy })
              : t('profile.application.badge.contacted')
          }
        />
      );
    case 'declined':
      return (
        <Tag
          size="md"
          bg="critical.100"
          color="critical.500"
          label={
            statusBy
              ? t('profile.application.badge.declinedBy', { name: statusBy })
              : t('profile.application.badge.declined')
          }
        />
      );
    default:
      return (
        <Tag
          size="md"
          bg="critical.100"
          color="decorative.fuschia.500"
          leftIcon={IconHeartFilled}
          label={t('profile.application.badge.favorite')}
        />
      );
  }
};
