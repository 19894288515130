import { AvatarGroup, Flex, Text } from '@collective/ui';
import { displayName } from '@collective/utils/shared';
import { useTranslation } from 'react-i18next';

import { ApprovalType } from '../sections/trusted-by-section/types';
import { OPEN_APPROVALS_EVENT } from '../sections/trusted-by-section/utils';

type SoloApplicationApprovalStatsProps = {
  approvals: ApprovalType[] | null;
};

export const SoloApplicationApprovalStats = ({
  approvals,
}: SoloApplicationApprovalStatsProps) => {
  const { t } = useTranslation('common');

  if (!approvals) {
    return null;
  }

  const avatars = approvals.map((approval, index) => ({
    id: index.toString(),
    name: displayName({
      firstname: approval.firstname,
      lastname: approval.lastname,
    }),
    src: approval.pictureUrl,
  }));

  const onClick = () => {
    document.dispatchEvent(new CustomEvent(OPEN_APPROVALS_EVENT));
  };

  return (
    <Flex
      align="center"
      gap="8px"
      w="fit-content"
      onClick={onClick}
      cursor="pointer"
      mb={2}
    >
      {!!approvals.length && (
        <AvatarGroup
          avatars={avatars}
          borderColor="primary.800"
          shownAvatarsTooltipProps={{ isDisabled: true }}
        />
      )}
      <Text color="rythm.700">
        {t('profile.approvals.counter', { count: approvals.length })}
      </Text>
    </Flex>
  );
};
