import { TFunction } from 'i18next';

import { LinkedinIndustryCode } from './mapping';

type LinkedInIndustryMapping = Record<LinkedinIndustryCode, string>;

export const getLinkedInIndustryMapping = (
  t: TFunction
): LinkedInIndustryMapping => {
  const mapping: LinkedInIndustryMapping = {
    '1': t('linkedInIndustry.1'),
    '3': t('linkedInIndustry.3'),
    '4': t('linkedInIndustry.4'),
    '5': t('linkedInIndustry.5'),
    '6': t('linkedInIndustry.6'),
    '7': t('linkedInIndustry.7'),
    '8': t('linkedInIndustry.8'),
    '9': t('linkedInIndustry.9'),
    '10': t('linkedInIndustry.10'),
    '11': t('linkedInIndustry.11'),
    '12': t('linkedInIndustry.12'),
    '13': t('linkedInIndustry.13'),
    '14': t('linkedInIndustry.14'),
    '15': t('linkedInIndustry.15'),
    '16': t('linkedInIndustry.16'),
    '17': t('linkedInIndustry.17'),
    '18': t('linkedInIndustry.18'),
    '19': t('linkedInIndustry.19'),
    '20': t('linkedInIndustry.20'),
    '21': t('linkedInIndustry.21'),
    '22': t('linkedInIndustry.22'),
    '23': t('linkedInIndustry.23'),
    '24': t('linkedInIndustry.24'),
    '25': t('linkedInIndustry.25'),
    '26': t('linkedInIndustry.26'),
    '27': t('linkedInIndustry.27'),
    '28': t('linkedInIndustry.28'),
    '29': t('linkedInIndustry.29'),
    '30': t('linkedInIndustry.30'),
    '31': t('linkedInIndustry.31'),
    '32': t('linkedInIndustry.32'),
    '33': t('linkedInIndustry.33'),
    '34': t('linkedInIndustry.34'),
    '35': t('linkedInIndustry.35'),
    '36': t('linkedInIndustry.36'),
    '37': t('linkedInIndustry.37'),
    '38': t('linkedInIndustry.38'),
    '39': t('linkedInIndustry.39'),
    '40': t('linkedInIndustry.40'),
    '41': t('linkedInIndustry.41'),
    '42': t('linkedInIndustry.42'),
    '43': t('linkedInIndustry.43'),
    '44': t('linkedInIndustry.44'),
    '45': t('linkedInIndustry.45'),
    '46': t('linkedInIndustry.46'),
    '47': t('linkedInIndustry.47'),
    '48': t('linkedInIndustry.48'),
    '49': t('linkedInIndustry.49'),
    '50': t('linkedInIndustry.50'),
    '51': t('linkedInIndustry.51'),
    '52': t('linkedInIndustry.52'),
    '53': t('linkedInIndustry.53'),
    '54': t('linkedInIndustry.54'),
    '55': t('linkedInIndustry.55'),
    '56': t('linkedInIndustry.56'),
    '57': t('linkedInIndustry.57'),
    '58': t('linkedInIndustry.58'),
    '59': t('linkedInIndustry.59'),
    '60': t('linkedInIndustry.60'),
    '61': t('linkedInIndustry.61'),
    '62': t('linkedInIndustry.62'),
    '63': t('linkedInIndustry.63'),
    '64': t('linkedInIndustry.64'),
    '65': t('linkedInIndustry.65'),
    '66': t('linkedInIndustry.66'),
    '67': t('linkedInIndustry.67'),
    '68': t('linkedInIndustry.68'),
    '69': t('linkedInIndustry.69'),
    '70': t('linkedInIndustry.70'),
    '71': t('linkedInIndustry.71'),
    '72': t('linkedInIndustry.72'),
    '73': t('linkedInIndustry.73'),
    '74': t('linkedInIndustry.74'),
    '75': t('linkedInIndustry.75'),
    '76': t('linkedInIndustry.76'),
    '77': t('linkedInIndustry.77'),
    '78': t('linkedInIndustry.78'),
    '79': t('linkedInIndustry.79'),
    '80': t('linkedInIndustry.80'),
    '81': t('linkedInIndustry.81'),
    '82': t('linkedInIndustry.82'),
    '83': t('linkedInIndustry.83'),
    '84': t('linkedInIndustry.84'),
    '85': t('linkedInIndustry.85'),
    '86': t('linkedInIndustry.86'),
    '87': t('linkedInIndustry.87'),
    '88': t('linkedInIndustry.88'),
    '89': t('linkedInIndustry.89'),
    '90': t('linkedInIndustry.90'),
    '91': t('linkedInIndustry.91'),
    '92': t('linkedInIndustry.92'),
    '93': t('linkedInIndustry.93'),
    '94': t('linkedInIndustry.94'),
    '95': t('linkedInIndustry.95'),
    '96': t('linkedInIndustry.96'),
    '97': t('linkedInIndustry.97'),
    '98': t('linkedInIndustry.98'),
    '99': t('linkedInIndustry.99'),
    '100': t('linkedInIndustry.100'),
    '101': t('linkedInIndustry.101'),
    '102': t('linkedInIndustry.102'),
    '103': t('linkedInIndustry.103'),
    '104': t('linkedInIndustry.104'),
    '105': t('linkedInIndustry.105'),
    '106': t('linkedInIndustry.106'),
    '107': t('linkedInIndustry.107'),
    '108': t('linkedInIndustry.108'),
    '109': t('linkedInIndustry.109'),
    '110': t('linkedInIndustry.110'),
    '111': t('linkedInIndustry.111'),
    '112': t('linkedInIndustry.112'),
    '113': t('linkedInIndustry.113'),
    '114': t('linkedInIndustry.114'),
    '115': t('linkedInIndustry.115'),
    '116': t('linkedInIndustry.116'),
    '117': t('linkedInIndustry.117'),
    '118': t('linkedInIndustry.118'),
    '119': t('linkedInIndustry.119'),
    '120': t('linkedInIndustry.120'),
    '121': t('linkedInIndustry.121'),
    '122': t('linkedInIndustry.122'),
    '123': t('linkedInIndustry.123'),
    '124': t('linkedInIndustry.124'),
    '125': t('linkedInIndustry.125'),
    '126': t('linkedInIndustry.126'),
    '127': t('linkedInIndustry.127'),
    '128': t('linkedInIndustry.128'),
    '129': t('linkedInIndustry.129'),
    '130': t('linkedInIndustry.130'),
    '131': t('linkedInIndustry.131'),
    '132': t('linkedInIndustry.132'),
    '133': t('linkedInIndustry.133'),
    '134': t('linkedInIndustry.134'),
    '135': t('linkedInIndustry.135'),
    '136': t('linkedInIndustry.136'),
    '137': t('linkedInIndustry.137'),
    '138': t('linkedInIndustry.138'),
    '139': t('linkedInIndustry.139'),
    '140': t('linkedInIndustry.140'),
    '141': t('linkedInIndustry.141'),
    '142': t('linkedInIndustry.142'),
    '143': t('linkedInIndustry.143'),
    '144': t('linkedInIndustry.144'),
    '145': t('linkedInIndustry.145'),
    '146': t('linkedInIndustry.146'),
    '147': t('linkedInIndustry.147'),
    '148': t('linkedInIndustry.148'),
    '150': t('linkedInIndustry.150'),
    '201': t('linkedInIndustry.201'),
    '256': t('linkedInIndustry.256'),
    '298': t('linkedInIndustry.298'),
    '332': t('linkedInIndustry.332'),
    '341': t('linkedInIndustry.341'),
    '345': t('linkedInIndustry.345'),
    '356': t('linkedInIndustry.356'),
    '382': t('linkedInIndustry.382'),
    '383': t('linkedInIndustry.383'),
    '384': t('linkedInIndustry.384'),
    '385': t('linkedInIndustry.385'),
    '386': t('linkedInIndustry.386'),
    '387': t('linkedInIndustry.387'),
    '388': t('linkedInIndustry.388'),
    '389': t('linkedInIndustry.389'),
    '390': t('linkedInIndustry.390'),
    '397': t('linkedInIndustry.397'),
    '398': t('linkedInIndustry.398'),
    '400': t('linkedInIndustry.400'),
    '404': t('linkedInIndustry.404'),
    '406': t('linkedInIndustry.406'),
    '408': t('linkedInIndustry.408'),
    '413': t('linkedInIndustry.413'),
    '419': t('linkedInIndustry.419'),
    '428': t('linkedInIndustry.428'),
    '431': t('linkedInIndustry.431'),
    '435': t('linkedInIndustry.435'),
    '436': t('linkedInIndustry.436'),
    '453': t('linkedInIndustry.453'),
    '460': t('linkedInIndustry.460'),
    '481': t('linkedInIndustry.481'),
    '495': t('linkedInIndustry.495'),
    '504': t('linkedInIndustry.504'),
    '521': t('linkedInIndustry.521'),
    '528': t('linkedInIndustry.528'),
    '529': t('linkedInIndustry.529'),
    '562': t('linkedInIndustry.562'),
    '564': t('linkedInIndustry.564'),
    '598': t('linkedInIndustry.598'),
    '615': t('linkedInIndustry.615'),
    '616': t('linkedInIndustry.616'),
    '622': t('linkedInIndustry.622'),
    '625': t('linkedInIndustry.625'),
    '679': t('linkedInIndustry.679'),
    '690': t('linkedInIndustry.690'),
    '703': t('linkedInIndustry.703'),
    '709': t('linkedInIndustry.709'),
    '722': t('linkedInIndustry.722'),
    '727': t('linkedInIndustry.727'),
    '743': t('linkedInIndustry.743'),
    '763': t('linkedInIndustry.763'),
    '773': t('linkedInIndustry.773'),
    '779': t('linkedInIndustry.779'),
    '784': t('linkedInIndustry.784'),
    '794': t('linkedInIndustry.794'),
    '799': t('linkedInIndustry.799'),
    '807': t('linkedInIndustry.807'),
    '840': t('linkedInIndustry.840'),
    '849': t('linkedInIndustry.849'),
    '852': t('linkedInIndustry.852'),
    '861': t('linkedInIndustry.861'),
    '871': t('linkedInIndustry.871'),
    '873': t('linkedInIndustry.873'),
    '876': t('linkedInIndustry.876'),
    '883': t('linkedInIndustry.883'),
    '887': t('linkedInIndustry.887'),
    '901': t('linkedInIndustry.901'),
    '918': t('linkedInIndustry.918'),
    '923': t('linkedInIndustry.923'),
    '928': t('linkedInIndustry.928'),
    '935': t('linkedInIndustry.935'),
    '964': t('linkedInIndustry.964'),
    '973': t('linkedInIndustry.973'),
    '983': t('linkedInIndustry.983'),
    '994': t('linkedInIndustry.994'),
    '998': t('linkedInIndustry.998'),
    '1005': t('linkedInIndustry.1005'),
    '1029': t('linkedInIndustry.1029'),
    '1042': t('linkedInIndustry.1042'),
    '1080': t('linkedInIndustry.1080'),
    '1090': t('linkedInIndustry.1090'),
    '1095': t('linkedInIndustry.1095'),
    '1128': t('linkedInIndustry.1128'),
    '1137': t('linkedInIndustry.1137'),
    '1153': t('linkedInIndustry.1153'),
    '1157': t('linkedInIndustry.1157'),
    '1166': t('linkedInIndustry.1166'),
    '1171': t('linkedInIndustry.1171'),
    '1178': t('linkedInIndustry.1178'),
    '1187': t('linkedInIndustry.1187'),
    '1206': t('linkedInIndustry.1206'),
    '1208': t('linkedInIndustry.1208'),
    '1212': t('linkedInIndustry.1212'),
    '1221': t('linkedInIndustry.1221'),
    '1222': t('linkedInIndustry.1222'),
    '1230': t('linkedInIndustry.1230'),
    '1231': t('linkedInIndustry.1231'),
    '1250': t('linkedInIndustry.1250'),
    '1257': t('linkedInIndustry.1257'),
    '1262': t('linkedInIndustry.1262'),
    '1267': t('linkedInIndustry.1267'),
    '1285': t('linkedInIndustry.1285'),
    '1292': t('linkedInIndustry.1292'),
    '1309': t('linkedInIndustry.1309'),
    '1319': t('linkedInIndustry.1319'),
    '1324': t('linkedInIndustry.1324'),
    '1339': t('linkedInIndustry.1339'),
    '1359': t('linkedInIndustry.1359'),
    '1370': t('linkedInIndustry.1370'),
    '1407': t('linkedInIndustry.1407'),
    '1409': t('linkedInIndustry.1409'),
    '1423': t('linkedInIndustry.1423'),
    '1424': t('linkedInIndustry.1424'),
    '1431': t('linkedInIndustry.1431'),
    '1445': t('linkedInIndustry.1445'),
    '1481': t('linkedInIndustry.1481'),
    '1495': t('linkedInIndustry.1495'),
    '1497': t('linkedInIndustry.1497'),
    '1504': t('linkedInIndustry.1504'),
    '1505': t('linkedInIndustry.1505'),
    '1512': t('linkedInIndustry.1512'),
    '1517': t('linkedInIndustry.1517'),
    '1520': t('linkedInIndustry.1520'),
    '1532': t('linkedInIndustry.1532'),
    '1573': t('linkedInIndustry.1573'),
    '1594': t('linkedInIndustry.1594'),
    '1600': t('linkedInIndustry.1600'),
    '1602': t('linkedInIndustry.1602'),
    '1611': t('linkedInIndustry.1611'),
    '1623': t('linkedInIndustry.1623'),
    '1625': t('linkedInIndustry.1625'),
    '1633': t('linkedInIndustry.1633'),
    '1641': t('linkedInIndustry.1641'),
    '1644': t('linkedInIndustry.1644'),
    '1649': t('linkedInIndustry.1649'),
    '1673': t('linkedInIndustry.1673'),
    '1678': t('linkedInIndustry.1678'),
    '1696': t('linkedInIndustry.1696'),
    '1713': t('linkedInIndustry.1713'),
    '1720': t('linkedInIndustry.1720'),
    '1725': t('linkedInIndustry.1725'),
    '1737': t('linkedInIndustry.1737'),
    '1738': t('linkedInIndustry.1738'),
    '1742': t('linkedInIndustry.1742'),
    '1743': t('linkedInIndustry.1743'),
    '1745': t('linkedInIndustry.1745'),
    '1750': t('linkedInIndustry.1750'),
    '1757': t('linkedInIndustry.1757'),
    '1759': t('linkedInIndustry.1759'),
    '1770': t('linkedInIndustry.1770'),
    '1779': t('linkedInIndustry.1779'),
    '1786': t('linkedInIndustry.1786'),
    '1798': t('linkedInIndustry.1798'),
    '1810': t('linkedInIndustry.1810'),
    '1855': t('linkedInIndustry.1855'),
    '1862': t('linkedInIndustry.1862'),
    '1905': t('linkedInIndustry.1905'),
    '1909': t('linkedInIndustry.1909'),
    '1911': t('linkedInIndustry.1911'),
    '1912': t('linkedInIndustry.1912'),
    '1916': t('linkedInIndustry.1916'),
    '1923': t('linkedInIndustry.1923'),
    '1925': t('linkedInIndustry.1925'),
    '1931': t('linkedInIndustry.1931'),
    '1938': t('linkedInIndustry.1938'),
    '1956': t('linkedInIndustry.1956'),
    '1958': t('linkedInIndustry.1958'),
    '1965': t('linkedInIndustry.1965'),
    '1981': t('linkedInIndustry.1981'),
    '1986': t('linkedInIndustry.1986'),
    '1999': t('linkedInIndustry.1999'),
    '2012': t('linkedInIndustry.2012'),
    '2018': t('linkedInIndustry.2018'),
    '2019': t('linkedInIndustry.2019'),
    '2020': t('linkedInIndustry.2020'),
    '2025': t('linkedInIndustry.2025'),
    '2027': t('linkedInIndustry.2027'),
    '2029': t('linkedInIndustry.2029'),
    '2040': t('linkedInIndustry.2040'),
    '2045': t('linkedInIndustry.2045'),
    '2048': t('linkedInIndustry.2048'),
    '2050': t('linkedInIndustry.2050'),
    '2054': t('linkedInIndustry.2054'),
    '2060': t('linkedInIndustry.2060'),
    '2063': t('linkedInIndustry.2063'),
    '2069': t('linkedInIndustry.2069'),
    '2074': t('linkedInIndustry.2074'),
    '2077': t('linkedInIndustry.2077'),
    '2081': t('linkedInIndustry.2081'),
    '2091': t('linkedInIndustry.2091'),
    '2112': t('linkedInIndustry.2112'),
    '2115': t('linkedInIndustry.2115'),
    '2122': t('linkedInIndustry.2122'),
    '2125': t('linkedInIndustry.2125'),
    '2128': t('linkedInIndustry.2128'),
    '2130': t('linkedInIndustry.2130'),
    '2133': t('linkedInIndustry.2133'),
    '2135': t('linkedInIndustry.2135'),
    '2139': t('linkedInIndustry.2139'),
    '2142': t('linkedInIndustry.2142'),
    '2143': t('linkedInIndustry.2143'),
    '2159': t('linkedInIndustry.2159'),
    '2161': t('linkedInIndustry.2161'),
    '2163': t('linkedInIndustry.2163'),
    '2167': t('linkedInIndustry.2167'),
    '2179': t('linkedInIndustry.2179'),
    '2181': t('linkedInIndustry.2181'),
    '2190': t('linkedInIndustry.2190'),
    '2194': t('linkedInIndustry.2194'),
    '2197': t('linkedInIndustry.2197'),
    '2212': t('linkedInIndustry.2212'),
    '2214': t('linkedInIndustry.2214'),
    '2217': t('linkedInIndustry.2217'),
    '2225': t('linkedInIndustry.2225'),
    '2226': t('linkedInIndustry.2226'),
    '2240': t('linkedInIndustry.2240'),
    '2247': t('linkedInIndustry.2247'),
    '2253': t('linkedInIndustry.2253'),
    '2255': t('linkedInIndustry.2255'),
    '2258': t('linkedInIndustry.2258'),
    '2259': t('linkedInIndustry.2259'),
    '2272': t('linkedInIndustry.2272'),
    '2282': t('linkedInIndustry.2282'),
    '2318': t('linkedInIndustry.2318'),
    '2353': t('linkedInIndustry.2353'),
    '2358': t('linkedInIndustry.2358'),
    '2360': t('linkedInIndustry.2360'),
    '2366': t('linkedInIndustry.2366'),
    '2368': t('linkedInIndustry.2368'),
    '2369': t('linkedInIndustry.2369'),
    '2374': t('linkedInIndustry.2374'),
    '2375': t('linkedInIndustry.2375'),
    '2391': t('linkedInIndustry.2391'),
    '2401': t('linkedInIndustry.2401'),
    '2458': t('linkedInIndustry.2458'),
    '2468': t('linkedInIndustry.2468'),
    '2489': t('linkedInIndustry.2489'),
    '2500': t('linkedInIndustry.2500'),
    '2934': t('linkedInIndustry.2934'),
    '3065': t('linkedInIndustry.3065'),
    '3068': t('linkedInIndustry.3068'),
    '3070': t('linkedInIndustry.3070'),
    '3081': t('linkedInIndustry.3081'),
    '3085': t('linkedInIndustry.3085'),
    '3086': t('linkedInIndustry.3086'),
    '3089': t('linkedInIndustry.3089'),
    '3095': t('linkedInIndustry.3095'),
    '3096': t('linkedInIndustry.3096'),
    '3099': t('linkedInIndustry.3099'),
    '3100': t('linkedInIndustry.3100'),
    '3101': t('linkedInIndustry.3101'),
    '3102': t('linkedInIndustry.3102'),
    '3103': t('linkedInIndustry.3103'),
    '3104': t('linkedInIndustry.3104'),
    '3105': t('linkedInIndustry.3105'),
    '3106': t('linkedInIndustry.3106'),
    '3107': t('linkedInIndustry.3107'),
    '3124': t('linkedInIndustry.3124'),
    '3125': t('linkedInIndustry.3125'),
    '3126': t('linkedInIndustry.3126'),
    '3127': t('linkedInIndustry.3127'),
    '3128': t('linkedInIndustry.3128'),
    '3129': t('linkedInIndustry.3129'),
    '3130': t('linkedInIndustry.3130'),
    '3131': t('linkedInIndustry.3131'),
    '3132': t('linkedInIndustry.3132'),
    '3133': t('linkedInIndustry.3133'),
    '3134': t('linkedInIndustry.3134'),
    '3186': t('linkedInIndustry.3186'),
    '3187': t('linkedInIndustry.3187'),
    '3188': t('linkedInIndustry.3188'),
    '3189': t('linkedInIndustry.3189'),
    '3190': t('linkedInIndustry.3190'),
    '3191': t('linkedInIndustry.3191'),
    '3192': t('linkedInIndustry.3192'),
    '3193': t('linkedInIndustry.3193'),
    '3194': t('linkedInIndustry.3194'),
    '3195': t('linkedInIndustry.3195'),
    '3196': t('linkedInIndustry.3196'),
    '3197': t('linkedInIndustry.3197'),
    '3198': t('linkedInIndustry.3198'),
    '3199': t('linkedInIndustry.3199'),
    '3200': t('linkedInIndustry.3200'),
    '3201': t('linkedInIndustry.3201'),
    '3202': t('linkedInIndustry.3202'),
    '3203': t('linkedInIndustry.3203'),
    '3204': t('linkedInIndustry.3204'),
    '3205': t('linkedInIndustry.3205'),
    '3206': t('linkedInIndustry.3206'),
    '3207': t('linkedInIndustry.3207'),
    '3208': t('linkedInIndustry.3208'),
    '3209': t('linkedInIndustry.3209'),
    '3210': t('linkedInIndustry.3210'),
    '3211': t('linkedInIndustry.3211'),
    '3212': t('linkedInIndustry.3212'),
    '3213': t('linkedInIndustry.3213'),
    '3214': t('linkedInIndustry.3214'),
    '3215': t('linkedInIndustry.3215'),
    '3216': t('linkedInIndustry.3216'),
    '3217': t('linkedInIndustry.3217'),
    '3218': t('linkedInIndustry.3218'),
    '3219': t('linkedInIndustry.3219'),
    '3220': t('linkedInIndustry.3220'),
    '3221': t('linkedInIndustry.3221'),
    '3222': t('linkedInIndustry.3222'),
    '3223': t('linkedInIndustry.3223'),
    '3224': t('linkedInIndustry.3224'),
    '3225': t('linkedInIndustry.3225'),
    '3226': t('linkedInIndustry.3226'),
    '3227': t('linkedInIndustry.3227'),
    '3228': t('linkedInIndustry.3228'),
    '3229': t('linkedInIndustry.3229'),
    '3230': t('linkedInIndustry.3230'),
    '3231': t('linkedInIndustry.3231'),
    '3232': t('linkedInIndustry.3232'),
    '3233': t('linkedInIndustry.3233'),
    '3234': t('linkedInIndustry.3234'),
    '3235': t('linkedInIndustry.3235'),
    '3236': t('linkedInIndustry.3236'),
    '3237': t('linkedInIndustry.3237'),
    '3238': t('linkedInIndustry.3238'),
    '3239': t('linkedInIndustry.3239'),
    '3240': t('linkedInIndustry.3240'),
    '3241': t('linkedInIndustry.3241'),
    '3242': t('linkedInIndustry.3242'),
    '3243': t('linkedInIndustry.3243'),
    '3244': t('linkedInIndustry.3244'),
    '3245': t('linkedInIndustry.3245'),
    '3246': t('linkedInIndustry.3246'),
    '3247': t('linkedInIndustry.3247'),
    '3248': t('linkedInIndustry.3248'),
    '3249': t('linkedInIndustry.3249'),
    '3250': t('linkedInIndustry.3250'),
    '3251': t('linkedInIndustry.3251'),
    '3252': t('linkedInIndustry.3252'),
    '3253': t('linkedInIndustry.3253'),
    '3254': t('linkedInIndustry.3254'),
    '3255': t('linkedInIndustry.3255'),
    '3256': t('linkedInIndustry.3256'),
  };

  return mapping;
};
