import {
  BorderBox,
  Box,
  Dot,
  Flex,
  Heading,
  MediaQueryHide,
  MediaQueryShow,
  Text,
  Tooltip,
} from '@collective/ui';
import dayjs from 'dayjs';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { CompanyLogo } from '../company-logo';
import { HighlightText } from '../highlighting/highlight-text';
import { TopRecruiterBadge } from '../top-recruiter-badge';
import { JobSkills } from './job-skills';
import { JobSource } from './job-source';
import { JobViewFormattedProps } from './types';

type JobGeneralInformationProps = JobViewFormattedProps & {
  cta?: ReactNode;
};

export const JobGeneralInformation = ({
  company,
  title,
  source,
  skills,
  suggestions,
  publishedAt,
  fromTopRecruiter,
  cta,
}: JobGeneralInformationProps) => {
  const { t } = useTranslation('common');

  return (
    <BorderBox
      noHover
      p="20px"
      display="flex"
      gap="24px"
      flexDirection="column"
    >
      <Flex align="center" gap="12px">
        <CompanyLogo
          name={company?.name}
          logoUrl={company?.logoUrl}
          avatarProps={{
            size: 'xl',
          }}
        />

        <Box>
          <HighlightText>
            <Text as="h2" mb={1}>
              {company?.name}
            </Text>
            <Heading as="h1" variant="desktop-l-semibold">
              {title}
            </Heading>
          </HighlightText>
        </Box>

        <Box ml="auto">
          <MediaQueryShow above="md">{cta}</MediaQueryShow>
        </Box>
      </Flex>

      <JobSkills skills={skills} suggestions={suggestions} />

      <Flex align="center" gap="4px">
        <Text variant="desktop-s-regular" color="rythm.700">
          {dayjs(publishedAt).from(dayjs())}
        </Text>
        <Dot bg="rythm.600" />
        <JobSource source={source} />
        {fromTopRecruiter && (
          <Tooltip label={t('job.topRecruiterTooltip')} shouldWrapChildren>
            <TopRecruiterBadge label={t('job.topRecruiter')} />
          </Tooltip>
        )}
      </Flex>
      <MediaQueryHide above="md">{cta}</MediaQueryHide>
    </BorderBox>
  );
};
