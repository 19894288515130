import {
  Box,
  Button,
  Dot,
  Flex,
  IconButton,
  IconEdit,
  IconTrash,
  Text,
  Tooltip,
  WrapperExpandable,
} from '@collective/ui';
import {
  ALL_IDENTIFIER,
  EXPERIENCES_IDENTIFIER,
} from '@collective/utils/frontend';
import { first } from 'lodash';
import { useTranslation } from 'react-i18next';

import { CompanyLogo } from '../../../company-logo';
import { HighlightText } from '../../../highlighting/highlight-text';
import { UserProfileWorkExperience } from '../../types';
import { ProfileDates } from '../dates';
import { ProfileRow, ProfileRowHeader } from '../row';
import { WorkExperienceActions } from './types';
import { getMinStartDateOfGroup } from './utils';

type WorkExperienceRowProps = {
  actions?: WorkExperienceActions;
  workExperiences: UserProfileWorkExperience[];
  isCollapsible: boolean;
  industryMapping: Record<string, string>;
};

export const WorkExperienceRow = ({
  workExperiences,
  isCollapsible,
  actions,
  industryMapping,
}: WorkExperienceRowProps) => {
  return workExperiences.length === 1 ? (
    <WorkExperienceSingle
      workExperience={workExperiences[0]}
      isCollapsible={isCollapsible}
      actions={actions}
      industryMapping={industryMapping}
    />
  ) : (
    <WorkExperienceMultiple
      workExperiences={workExperiences}
      isCollapsible={isCollapsible}
      actions={actions}
      industryMapping={industryMapping}
    />
  );
};

type WorkExperienceSingleProps = {
  workExperience: UserProfileWorkExperience;
  isCollapsible: boolean;
  actions?: WorkExperienceActions;
  industryMapping: Record<string, string>;
};

const WorkExperienceSingle = ({
  workExperience,
  isCollapsible,
  actions,
  industryMapping,
}: WorkExperienceSingleProps) => {
  const { t } = useTranslation('common');

  const {
    company,
    description,
    title,
    location,
    logoUrl,
    isRemote,
    index,
    isFreelanceContract,
    industryCode,
  } = workExperience;

  return (
    <ProfileRow role="group" data-dd-id={`work-experience-${index}`}>
      <Box>
        <CompanyLogo logoUrl={logoUrl} name={company || title} />
      </Box>
      {/*
        minWidth 0 to avoid children flex elements from overflowing and shrinking correctly
        source: https://www.bigbinary.com/blog/understanding-the-automatic-minimum-size-of-flex-items
      */}
      <Flex direction="column" flex={1} minWidth={0}>
        <ProfileRowHeader
          title={title}
          subtitle={
            <Company
              company={company}
              isFreelanceContract={isFreelanceContract}
              industry={
                industryCode ? industryMapping[industryCode] : undefined
              }
            />
          }
          location={
            isRemote ? t('profile.workExperience.remoteLabel') : location
          }
          dates={<ProfileDates {...workExperience} />}
          mb={4}
          actions={
            actions ? (
              <Actions actions={actions} workExperience={workExperience} />
            ) : null
          }
          highlightIdentifiers={[EXPERIENCES_IDENTIFIER, ALL_IDENTIFIER]}
        />

        {isCollapsible ? (
          <WrapperExpandable
            numberOfLinesBeforeExpand={2}
            isHTML={false}
            content={description || ''}
            elementToDisplayWhenExpandable={
              <Button
                variant="plain"
                position="absolute"
                right={0}
                bottom="-2px"
                bgColor="white"
                pl={4}
              >
                {t('profile.workExperience.seeMore')}
              </Button>
            }
          />
        ) : (
          <HighlightText identifiers={[EXPERIENCES_IDENTIFIER, ALL_IDENTIFIER]}>
            <Text whiteSpace="pre-line" wordBreak="break-word">
              {description}
            </Text>
          </HighlightText>
        )}
      </Flex>
    </ProfileRow>
  );
};

type WorkExperienceMultipleProps = {
  workExperiences: UserProfileWorkExperience[];
  isCollapsible: boolean;
  actions?: WorkExperienceActions;
  industryMapping: Record<string, string>;
};

const WorkExperienceMultiple = ({
  workExperiences,
  isCollapsible,
  actions,
  industryMapping,
}: WorkExperienceMultipleProps) => {
  const { logoUrl, company, location, isFreelanceContract, industryCode } =
    workExperiences[0];
  const { t } = useTranslation('common');

  const dates = (
    <ProfileDates
      startDate={getMinStartDateOfGroup(workExperiences)}
      endDate={first(workExperiences)?.endDate}
    />
  );

  return (
    <ProfileRow direction="column">
      <Flex gap="16px">
        <Box>
          <CompanyLogo logoUrl={logoUrl} name={company} />
        </Box>
        <ProfileRowHeader
          title={
            <Company
              company={company}
              isFreelanceContract={isFreelanceContract}
              industry={
                industryCode ? industryMapping[industryCode] : undefined
              }
            />
          }
          location={location}
          dates={dates}
          highlightIdentifiers={[EXPERIENCES_IDENTIFIER, ALL_IDENTIFIER]}
        />
      </Flex>

      {workExperiences.map((workExperience) => {
        const { id, description, title, location, index } = workExperience;

        return (
          <Box
            key={id}
            role="group"
            position="relative"
            pl="calc(48px + 16px)"
            _last={{
              '.line': { display: 'none' },
            }}
            data-dd-id={`work-experience-${index}`}
          >
            <Box
              className="line"
              w="2px"
              height="calc(100% + 16px)"
              bg="rythm.200"
              position="absolute"
              top="12px"
              left="calc(24px - 1px)"
            />
            <Dot
              size="8px"
              outline="2px solid white"
              position="absolute"
              top="8px"
              left="calc(24px - 4px)"
            />

            <ProfileRowHeader
              title={title}
              location={location}
              dates={<ProfileDates {...workExperience} />}
              mb={4}
              actions={
                actions ? (
                  <Actions actions={actions} workExperience={workExperience} />
                ) : null
              }
              highlightIdentifiers={[EXPERIENCES_IDENTIFIER, ALL_IDENTIFIER]}
              isNested
            />

            {isCollapsible ? (
              <WrapperExpandable
                numberOfLinesBeforeExpand={2}
                isHTML={false}
                content={description || ''}
                elementToDisplayWhenExpandable={
                  <Button
                    variant="plain"
                    position="absolute"
                    right={0}
                    bottom="-2px"
                    bgColor="white"
                    pl={4}
                  >
                    {t('profile.workExperience.seeMore')}
                  </Button>
                }
              />
            ) : (
              <HighlightText
                identifiers={[EXPERIENCES_IDENTIFIER, ALL_IDENTIFIER]}
              >
                <Text whiteSpace="pre-line" wordBreak="break-word">
                  {description}
                </Text>
              </HighlightText>
            )}
          </Box>
        );
      })}
    </ProfileRow>
  );
};

type CompanyProps = {
  company: UserProfileWorkExperience['company'];
  isFreelanceContract: UserProfileWorkExperience['isFreelanceContract'];
  industry?: string;
};

const Company = ({ company, isFreelanceContract, industry }: CompanyProps) => {
  const { t } = useTranslation('common');

  if (!company) {
    return null;
  }

  return (
    <Flex align="center" gap="4px" as="span">
      {company}
      {isFreelanceContract && (
        <>
          <Dot as="span" display="block" />
          {t('profile.workExperience.freelance')}
        </>
      )}
      {industry && (
        <>
          <Dot as="span" display="block" />
          <Text color="rythm.700" variant="desktop-s-regular">
            {industry}
          </Text>
        </>
      )}
    </Flex>
  );
};

type ActionsProps = {
  actions: WorkExperienceActions;
  workExperience: UserProfileWorkExperience;
};

const Actions = ({ actions, workExperience }: ActionsProps) => {
  const { t } = useTranslation('common');

  return (
    <Flex gap="8px">
      <Tooltip label={t('profile.workExperience.editLabel')}>
        <IconButton
          data-dd-id="work-experience-edit"
          onClick={() => actions.onEditClick(workExperience)}
          display="none"
          _groupHover={{ display: 'flex' }}
          variant="iconButton"
          icon={<IconEdit />}
          aria-label={t('profile.workExperience.editLabel')}
        />
      </Tooltip>
      <Tooltip label={t('profile.workExperience.deleteLabel')}>
        <IconButton
          data-dd-id="work-experience-delete"
          onClick={() => actions.onDeleteClick(workExperience)}
          display="none"
          _groupHover={{ display: 'flex' }}
          variant="iconButton"
          icon={<IconTrash />}
          aria-label={t('profile.workExperience.deleteLabel')}
        />
      </Tooltip>
    </Flex>
  );
};
