import utilsDate, { dayjs } from '@collective/utils/date';
import { orderWorkExperiences } from '@collective/utils/shared';
import cuid from 'cuid';
import lodash, { isNil, maxBy, minBy, partition } from 'lodash';

import { UserProfileWorkExperience } from '../../types';

type WorkExperienceGroup = {
  id: string;
  company: string;
  items: UserProfileWorkExperience[];
};

export const groupWorkExperiences = (
  records: UserProfileWorkExperience[]
): WorkExperienceGroup[] => {
  const now = new Date();

  return (
    lodash
      .chain(records)
      // initial sort by ranking to have ranked items as first in the grouping
      .sortBy('ranking')
      // group by company name
      .groupBy('company')
      // We put the active experience first, then the old ones, sorted on endDate then startDate
      // It's important to have this initial sort to calculate easily the date overlaps
      .map((group) => {
        const [currentXP, pastXP] = partition(group, ({ endDate }) => !endDate);

        return orderWorkExperiences(lodash, currentXP, pastXP);
      })
      // We check if experiences overlap with each others. If not, we create new groups
      // This way, if for a same company you have a gap of some months/years, you'll have 2 (or more) groups
      .reduce<UserProfileWorkExperience[][]>((groups, group) => {
        const newSubgroups: UserProfileWorkExperience[][] = [];

        for (const experience of group) {
          const overlapedInGroup = newSubgroups.find((subgroup) =>
            subgroup.find(
              (xp) =>
                xp.id !== experience.id &&
                utilsDate.isOverlaping(
                  [
                    // Add 2 days to the end date to handle two consecutives months to consider them as overlaping
                    xp.startDate || now,
                    dayjs(xp.endDate || now)
                      .add(2, 'day')
                      .toISOString(),
                  ],
                  [
                    // Add 2 days to the end date to handle two consecutives months to consider them as overlaping
                    experience.startDate || now,
                    dayjs(experience.endDate || now)
                      .add(2, 'day')
                      .toISOString(),
                  ]
                )
            )
          );
          if (overlapedInGroup) {
            overlapedInGroup.push(experience);
          } else {
            newSubgroups.push([experience]);
          }
        }

        // We don't have to re-order the content of each groups as they were in the right order
        // And we push back values to the new subgroups should keep the right order

        return [...groups, ...newSubgroups];
      }, [])
      // We format the data of the groups
      .map((group) => {
        return {
          id: cuid(),
          company: group[0].company || cuid(),
          items: group,
        };
      })
      .value()
      // We re-sort as the new groups created are not sorted at the right place
      // The idea is to put the active groups first
      .sort((groupA, groupB) => {
        // Current work experience go first
        if (!groupA.items[0].endDate && groupB.items[0].endDate) {
          return -1;
        }
        if (groupA.items[0].endDate && !groupB.items[0].endDate) {
          return 1;
        }

        // If there is a ranking on current experience, we order them
        if (
          !groupA.items[0].endDate &&
          !groupB.items[0].endDate &&
          (!isNil(groupA.items[0].ranking) || !isNil(groupB.items[0].ranking))
        ) {
          return (
            (groupA.items[0].ranking || 0) - (groupB.items[0].ranking || 0)
          );
        }

        const groupAStartDate = getMinStartDateOfGroup(groupA.items) || now;
        const groupAEndDate = getMaxEndDateOfGroup(groupA.items) || now;

        const groupBStartDate = getMinStartDateOfGroup(groupB.items) || now;
        const groupBEndDate = getMaxEndDateOfGroup(groupB.items) || now;

        // Last sort condition is based on the start date, mainly for old experiences
        if (groupAStartDate && !groupBStartDate) {
          return -1;
        } else if (!groupAStartDate && groupBStartDate) {
          return 1;
        }

        // we sort by end date first
        if (groupBEndDate !== groupAEndDate) {
          return (
            new Date(groupBEndDate).getTime() -
            new Date(groupAEndDate).getTime()
          );
        }

        // if end dates are the same, we sort by start date
        return (
          new Date(groupBStartDate).getTime() -
          new Date(groupAStartDate).getTime()
        );
      })
  );
};

export const getMinStartDateOfGroup = (
  groupItems: UserProfileWorkExperience[] | undefined
) => {
  return minBy(groupItems, (item) => item.startDate)?.startDate;
};

export const getMaxEndDateOfGroup = (
  groupItems: UserProfileWorkExperience[] | undefined
) => {
  return maxBy(groupItems, (item) => item.endDate)?.endDate;
};
